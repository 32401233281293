@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size: 16px;
  }
}
html body {
    -webkit-user-select: none;
            user-select: none;
}

.blur-text {
            font-size: 36px;
            color: rgb(199, 0, 0);
            text-shadow: 0 0 5px rgb(233, 89, 0); /* Menambahkan efek blur */
        }