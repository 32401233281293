.input-container {
  position: relative;
  width: 300px;
}

/* Image icon style */
.input-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/* Input style */
.input-field {
  width: calc(100% ); /* Consider icon width */
  padding: 10px 30px; /* Adjust padding to make space for the icon */
  border: 1px solid #ccc;
  border-radius: 5px;
}
